import React from "react";
import styled from "styled-components";
import {Link} from "gatsby";


const BlogCardContainer = styled.div`
  margin-top: 40px;
  padding: 2rem 2rem;
  box-shadow:-4px -4px 10px 2px rgba(58, 58, 58, 0.7), 4px 4px 10px 0 rgba(0, 0, 0, 0.7);
  border-radius: 20px;
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  max-width: 750px;
`;

const BlogTitle = styled.h2`
  color: var(--blue-highlight-color);
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
`;

const BlogSummary = styled.p`
  margin-top: 20px;
  color: var(--primary-text-color);
  text-align: center;
`;

const BlogDate = styled(BlogSummary)`
  
`;

const BlogCard = ({cardData})=>{
    const {slug, title, summary, date} = cardData;

    return <StyledLink to={slug}>
        <BlogCardContainer>
                <BlogTitle>{ title } </BlogTitle>
                <BlogSummary>{summary}</BlogSummary>
                <BlogDate>{date}</BlogDate>
        </BlogCardContainer>
    </StyledLink>
}

export default BlogCard;