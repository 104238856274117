import * as React from "react"
import styled from "styled-components";
import BlogCard from "../../components/blog/BlogCard";
import {blogData} from '../../utils/blogData'
import SEO from "../../components/SEO";

const StyledMainContent = styled.main`
  padding: var(--main-padding-mob);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledHeading = styled.h1`
  font-size: 2rem;
  margin-bottom: 10px;
  text-align: center;
  color: var(--primary-text-color);  
`;

const StyledParagraph = styled.p`
  font-size: 1rem;
  text-align: center;
  color: var(--primary-text-color);
  font-weight: normal;
  margin-top: 15px;
`;



const BlogPage = ({location}) =>{
    return <StyledMainContent>
        <SEO
            title="Going Shopping Blog - Learn To Optimize Your Shopping Trips"
            description="There is nothing more frustrating than feeling completely overwhelmed by grocery runs. Read all about how to optimize your shopping time on our blog."
            location={location}
        />
        <HeroSection>
            <StyledHeading>Going Shopping Blog</StyledHeading>
            <StyledParagraph>Tips and shopping lists for better organized grocery trips.</StyledParagraph>
        </HeroSection>
        <BlogCard cardData={blogData["post-2023-3"]}/>
        <BlogCard cardData={blogData["post-2023-2"]}/>
        <BlogCard cardData={blogData["post-2023-1"]}/>
        <BlogCard cardData={blogData["post-2022-17"]}/>
        <BlogCard cardData={blogData["post-2022-16"]}/>
        <BlogCard cardData={blogData["post-2022-15"]}/>
        <BlogCard cardData={blogData["post-2022-14"]}/>
        <BlogCard cardData={blogData["post-2022-13"]}/>
        <BlogCard cardData={blogData["post-2022-12"]}/>
        <BlogCard cardData={blogData["post-2022-11"]}/>
        <BlogCard cardData={blogData["post-2022-10"]}/>
        <BlogCard cardData={blogData["post-2022-9"]}/>
        <BlogCard cardData={blogData["post-2022-8"]}/>
        <BlogCard cardData={blogData["post-2022-7"]}/>
        <BlogCard cardData={blogData["post-2022-6"]}/>
        <BlogCard cardData={blogData["post-2022-5"]}/>
        <BlogCard cardData={blogData["post-2022-4"]}/>
        <BlogCard cardData={blogData["post-2022-3"]}/>
        <BlogCard cardData={blogData["post-2022-2"]}/>
        <BlogCard cardData={blogData["post-2022-1"]}/>
        
    </StyledMainContent>
}


export default BlogPage;