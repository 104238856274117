export const blogData = {
    "post-2022-1": {
        slug: "/how-to-organize-your-grocery-shopping-list/",
        date: "May 30",
        title: "5 Practical Tips On How To Organize Your Grocery Shopping List",
        description: "Let’s talk about how to make an organized grocery shopping list and save you from an insane number of headaches in the long run.",
        summary: "Let’s talk about how to make an organized grocery shopping list and save you from an insane number of headaches in the long run.",
        featuredImage:"blogs/featured/5-Practical-Tips-On-How-To-Organize-Your-Grocery-Shopping-List.jpg",
    },
    "post-2022-2": {
        slug: "/how-to-save-money-grocery-shopping/",
        date: "June 22",
        title: "9 Pro Tips On How To Save Money Grocery Shopping",
        description: "By implementing one or more of our tips for saving money on grocery shopping, we are sure that your grocery trips will become way less stressful.",
        summary: "By implementing one or more of our tips for saving money on grocery shopping, we are sure that your grocery trips will become way less stressful.",
        featuredImage:"blogs/featured/9-Pro-Tips-On-How-To-Save-Money-Grocery-Shopping.jpg",
    },
    "post-2022-3": {
        slug: "/when-is-the-best-time-to-go-grocery-shopping/",
        date: "July 7",
        title: "When Is The Best Time To Go Grocery Shopping?",
        description: "If you choose your shopping time and day carefully, performing this daily or weekly chore doesn't have to be stressful at all.",
        summary: "If you choose your shopping time and day carefully, performing this daily or weekly chore doesn't have to be stressful at all.",
        featuredImage:"blogs/featured/When-Is-The-Best-Time-To-Go-Grocery-Shopping.jpg",
    },
    "post-2022-4": {
        slug: "/how-to-go-grocery-shopping-with-a-baby/",
        date: "July 15",
        title: "How To Go Grocery Shopping With A Baby",
        description: "There is no need to stress yourself out over grocery shopping with a baby. With the right preparations, you can handle this without breaking a sweat!",
        summary: "There is no need to stress yourself out over grocery shopping with a baby. With the right preparations, you can handle this without breaking a sweat!",
        featuredImage:"blogs/featured/How-To-Go-Grocery-Shopping-With-A-Baby.jpg",
    },
    "post-2022-5": {
        slug: "/grocery-shopping-tips/",
        date: "July 24",
        title: "Grocery Shopping Tips We All Need In Our Life",
        description: "Grocery shopping can come with a lot of challenges. Here are battle-tested grocery shopping tips that will greatly improve your experience.",
        summary: "Grocery shopping can come with a lot of challenges. Here are battle-tested grocery shopping tips that will greatly improve your experience.",
        featuredImage:"blogs/featured/Grocery-Shopping-Tips-We-All-Need-In-Our-Life.jpg",
    },
    "post-2022-6": {
        slug: "/pantry-stock-list/",
        date: "August 16",
        title: "Stock Your Pantry For The Ultimate Home Cook Experience: Guide & Checklists",
        description: "Read all about the essential items every pantry needs and how to stock your pantry so that you have all your cooking and baking bases covered.",
        summary: "Read all about the essential items every pantry needs and how to stock your pantry so that you have all your cooking and baking bases covered.",
        featuredImage:"blogs/Stock-Your-Pantry-For-The-Ultimate-Home-Cook-Experience.jpg",
    },
    "post-2022-7": {
        slug: "/essential-pantry-items/",
        date: "August 25",
        title: "Essential Pantry Items To Stock Up On",
        description: "Here is a list of all the basic, essential items your pantry needs, neatly sorted into categories. Customize them to fit your cooking needs!",
        summary: "Here is a list of all the basic, essential items your pantry needs, neatly sorted into categories. Customize them to fit your cooking needs!",
        featuredImage:"blogs/featured/essential-pantry-items-to-stock-up-on.jpg",
    },
    "post-2022-8": {
        slug: "/how-to-stock-a-pantry-for-the-first-time/",
        date: "September 1",
        title: "How To Stock A Pantry For The First Time",
        description: "Here are five easy steps that will take you from an empty pantry to one fully stocked with all the essential cooking and baking ingredients.",
        summary: "Here are five easy steps that will take you from an empty pantry to one fully stocked with all the essential cooking and baking ingredients.",
        featuredImage:"blogs/featured/how-to-stock-a-pantry-for-the-first-time.jpg",
    },
    "post-2022-9": {
        slug: "/how-to-stock-a-pantry-for-a-year/",
        date: "September 8",
        title: "How To Stock A Pantry For A Year",
        description: "Stocking a pantry for a year is a significant undertaking. But once you break it down into actionable steps, it isn't that difficult to pull off.",
        summary: "Stocking a pantry for a year is a significant undertaking. But once you break it down into actionable steps, it isn't that difficult to pull off.",
        featuredImage:"blogs/featured/how-to-stock-a-pantry-for-a-year.jpg",
    },
    "post-2022-10": {
        slug: "/pantry-staples-list/",
        date: "September 15",
        title: "The Only Pantry Staples List You Will Ever Need",
        description: "Whether you're looking for basic pantry staples, healthy ones, those geared towards vegans or keto enthusiasts, this article has them all.",
        summary: "Whether you're looking for basic pantry staples, healthy ones, those geared towards vegans or keto enthusiasts, this article has them all.",
        featuredImage:"blogs/featured/the-only-pantry-staples-list-you-will-ever-need.jpg",
	},
    "post-2022-11": {
        slug: "/vegan-pantry-staples/",
        date: "September 22",
        title: "Vegan Pantry Staples: Kickstart Your Vegan Journey",
        description: "Whether you're a baby vegan or just want to lower your animal product consumption, you could benefit from this list of vegan pantry staples.",
        summary: "Whether you're a baby vegan or just want to lower your animal product consumption, you could benefit from this list of vegan pantry staples.",
        featuredImage:"blogs/featured/vegan-pantry-staples-kickstart-your-vegan-journey.jpg",
	},
    "post-2022-12": {
        slug: "/keto-pantry-staples/",
        date: "September 29",
        title: "Crush Your Keto Diet With This Ultimate Keto Pantry Staples List",
        description: "What should you stock up on if you're on the keto diet? Here is a full list of keto pantry staples that will help you breeze through keto.",
        summary: "What should you stock up on if you're on the keto diet? Here is a full list of keto pantry staples that will help you breeze through keto.",
        featuredImage:"blogs/featured/Crush-Your-Keto-Diet-With-This-Ultimate-Keto-Pantry-Staples-List.jpg",
	},
    "post-2022-13": {
        slug: "/healthy-pantry-staples/",
        date: "October 12",
        title: "Healthy Pantry Staples For A Well-Balanced Diet",
        description: "A well-balanced healthy diet is chock full of pantry staples such as fruits and vegetables, legumes, nuts and seeds, and healthy fats.",
        summary: "A well-balanced healthy diet is chock full of pantry staples such as fruits and vegetables, legumes, nuts and seeds, and healthy fats.",
        featuredImage:"blogs/featured/Healthy-Pantry-Staples-For-A-Well-Balanced-Diet.jpg",
	},
    "post-2022-14": {
        slug: "/back-to-school-clothes-shopping-list/",
        date: "November 03",
        title: "Back To School Clothes Shopping Made Easy",
        description: "Tired of not knowing what kids' clothes to get every new school year? Here's a neat little printable to save you the headache.",
        summary: "Tired of not knowing what kids' clothes to get every new school year? Here's a neat little printable to save you the headache. ",
        featuredImage:"blogs/featured/Healthy-Pantry-Staples-For-A-Well-Balanced-Diet.jpg",
	},
    "post-2022-15": {
        slug: "/low-fodmap-shopping-list/",
        date: "December 1",
        title: "The Low FODMAP Shopping List Done Right",
        description: "A low FODMAP diet can be effective in managing IBS, especially because the end result is a personalized diet that can be maintained over time.",
        summary: "A science-backed shopping list to help you get started on your low FODMAP journey. Plus free printables inside.",
        featuredImage:"blogs/featured/Low-FODMAP-Shopping-List-clean.jpg",
	},
    "post-2022-16": {
        slug: "/plant-based-diet-shopping-list/",
        date: "December 19",
        title: "Super Simple Plant Based Diet Shopping List",
        description: "A plant based diet is proven to be one of the best things you can do to prevent diseases, manage your weight, and improve your overall energy.",
        summary: "Here's a grocery list to get you started on transitioning to a plant-based diet. We promise it's not difficult!",
        featuredImage:"blogs/featured/plant-based-diet-shopping-list.jpg",
	},
    "post-2022-17": {
        slug: "/shopping-list-for-insulin-resistance-diet/",
        date: "December 27",
        title: "Shopping List For Insulin Resistance Diet",
        description: "One of the best ways to reverse or manage insulin resistance is through diet. Here is a shopping list for insulin resistance to start you off.",
        summary: "One of the best ways to reverse or manage insulin resistance is through diet. Here is a shopping list for insulin resistance to start you off.",
        featuredImage:"blogs/featured/shopping-list-for-insulin-resistance-diet.jpg",
	},
    "post-2023-1": {
        slug: "/paleo-shopping-list/",
        date: "January 10",
        title: "Paleo Shopping List For Beginners",
        description: "If you're interested in following a paleo diet, we're here to help with a paleo shopping list you can start using right away!",
        summary: "If you're interested in following a paleo diet, we're here to help with a paleo shopping list you can start using right away!",
        featuredImage:"blogs/featured/paleo-shopping-list.jpg",
	},
    "post-2023-2": {
        slug: "/diabetic-shopping-list/",
        date: "January 27",
        title: "Diabetic Shopping List: Best And Worst Foods",
        description: "Learn which foods to eat more of and which you should avoid to best regulate your blood sugar levels. A simple diabetic shopping list!",
        summary: "Learn which foods to eat more of and which you should avoid to best regulate your blood sugar levels. A simple diabetic shopping list!",
        featuredImage:"blogs/featured/diabetic-shopping-list.jpg",
	},
    "post-2023-3": {
        slug: "/blood-type-o-shopping-list/",
        date: "February 9",
        title: "What Is The Blood Type O Diet? Guide + Shopping List",
        description: "Discover the secrets to maintaining a healthy body with the Blood Type O Diet in our guide that provides a shopping list and tips for weight loss.",
        summary: "Discover the secrets to maintaining a healthy body with the Blood Type O Diet in our guide that provides a shopping list and tips for weight loss.",
        featuredImage:"blogs/featured/blood-type-O-shopping-list.jpg",
	},
    
}
